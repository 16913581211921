import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../environments/environment";

@Injectable()
export class AuthService {
  authToken: any;
  token: any;
  baseUrl = environment.test;
  headers1 = { headers: { Authorization: "Bearer " + this.token } };

  constructor(private http: HttpClient) {}

  login(data) {
    return this.http
      .post(this.baseUrl + "admin/login", data)
      .toPromise()
      .then((res) => res);
  }

  getProfile() {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "user/profile-info", headers)
      .toPromise()
      .then((res) => res);
  }

  editProfile(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "user/edit-info", data, headers)
      .toPromise()
      .then((res) => res);
  }

  forgotPassword(data) {
    return this.http
      .post(this.baseUrl + "user/forgot-password", data)
      .toPromise()
      .then((res) => res);
  }

  adminList(data?: any) {
    let headers = {
      params: data,
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "admin/get-admin", headers)
      .toPromise()
      .then((res) => res);
  }

  addAdmin(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "admin/add-admin", data, headers)
      .toPromise()
      .then((res) => res);
  }

  adminDetail(id) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "admin/detail/" + id, headers)
      .toPromise()
      .then((res) => res);
  }

  editAdmin(data, id) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .put(this.baseUrl + "admin/edit-admin/" + id, data, headers)
      .toPromise()
      .then((res) => res);
  }

  serviceList(data?: any) {
    let headers = {
      params: data,
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "admin/get-service", headers)
      .toPromise()
      .then((res) => res);
  }

  getNotifications() {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "cases/get-notification", headers)
      .toPromise()
      .then((res) => res);
  }

  updateNotifications() {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "cases/update-notification", headers)
      .toPromise()
      .then((res) => res);
  }

  addService(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "admin/add-service", data, headers)
      .toPromise()
      .then((res) => res);
  }

  serviceDetail(id) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "admin/detail-service/" + id, headers)
      .toPromise()
      .then((res) => res);
  }

  editService(data, id) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .put(this.baseUrl + "admin/edit-service/" + id, data, headers)
      .toPromise()
      .then((res) => res);
  }

  deleteService(data, id) {
    console.log("in services---->", data);
    console.log("in services---->", id);
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .put(this.baseUrl + "admin/delete-cases/" + id, data, headers)
      .toPromise()
      .then((res) => res);
  }

  addMoreService(data, id) {
    console.log(data);
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .put(this.baseUrl + "admin/edit-cases/" + id, data, headers)
      .toPromise()
      .then((res) => res);
  }

  changeDelivery(data, id) {
    console.log(data);
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .put(this.baseUrl + "admin/edit-casedelivery/" + id, data, headers)
      .toPromise()
      .then((res) => res);
  }

  deliveryTypeList(data?: any) {
    let headers = {
      params: data,
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "admin/get-delivery", headers)
      .toPromise()
      .then((res) => res);
  }

  addDeliveryType(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "admin/add-delivery", data, headers)
      .toPromise()
      .then((res) => res);
  }

  deliveryTypeDetail(id) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "admin/detail-delivery/" + id, headers)
      .toPromise()
      .then((res) => res);
  }

  editDeliveryType(data, id) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .put(this.baseUrl + "admin/edit-delivery/" + id, data, headers)
      .toPromise()
      .then((res) => res);
  }

  studyPurposeList(data?: any) {
    let headers = {
      params: data,
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "admin/get-studytype", headers)
      .toPromise()
      .then((res) => res);
  }

  addStudyPurpose(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "admin/add-studytype", data, headers)
      .toPromise()
      .then((res) => res);
  }

  studyPurposeDetail(id) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "admin/detail-studytype/" + id, headers)
      .toPromise()
      .then((res) => res);
  }

  editStudyPurpose(data, id) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .put(this.baseUrl + "admin/edit-studytype/" + id, data, headers)
      .toPromise()
      .then((res) => res);
  }

  addNewPromotion(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "promotion/add-promotion", data, headers)
      .toPromise()
      .then((res) => res);
  }

  getPromotionList(data?: any) {
    let headers = {
      params: data,
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "promotion/promotion", headers)
      .toPromise()
      .then((res) => res);
  }

  promotionDetail(id) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "promotion/detail-promotion/" + id, headers)
      .toPromise()
      .then((res) => res);
  }

  editPromotion(data, id) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .put(this.baseUrl + "promotion/update-promotion/" + id, data, headers)
      .toPromise()
      .then((res) => res);
  }

  deletePromotion(id) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .delete(this.baseUrl + "promotion/promotion-delete/" + id, headers)
      .toPromise()
      .then((res) => res);
  }

  radiologistList(data?: any) {
    let headers = {
      params: data,
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "radiologist/get", headers)
      .toPromise()
      .then((res) => res);
  }

  addRadiologist(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "radiologist/add", data, headers)
      .toPromise()
      .then((res) => res);
  }

  radiologistDetail(id) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "radiologist/detail/" + id, headers)
      .toPromise()
      .then((res) => res);
  }

  editRadiologist(data, id) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .put(this.baseUrl + "radiologist/edit/" + id, data, headers)
      .toPromise()
      .then((res) => res);
  }

  casesList(data?: any, filter?: any) {
    let headers = {
      params: data,
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "admin/doctor-caselist", filter, headers)
      .toPromise()
      .then((res) => res);
  }

  getData() {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "cases/form-data", headers)
      .toPromise()
      .then((res) => res);
  }

  assignRadiologist(id, data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "cases/update-case-status/" + id, data, headers)
      .toPromise()
      .then((res) => res);
  }

  caseSummary(id) {
    let data = { _id: id };
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "cases/cases-list", data, headers)
      .toPromise()
      .then((res) => res);
  }

  cancelCase(id: any) {
    console.log(typeof id);
    let data = { _id: id };
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    console.log(this.baseUrl);
    return this.http
      .post(this.baseUrl + "admin/cancelStatus", data, headers)
      .toPromise()
      .then((res) => res);
  }

  postComment(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "cases/add-comment", data, headers)
      .toPromise()
      .then((res) => res);
  }

  getComments(id) {
    let data = { headers: { Authorization: localStorage.getItem("id_token") } };
    return this.http
      .get(this.baseUrl + "cases/comment/" + id, data)
      .toPromise()
      .then((res) => res);
  }

  uploadFile(id, file) {
    let data = { headers: { Authorization: localStorage.getItem("id_token") } };
    return this.http
      .put(this.baseUrl + "cases/edit-case/" + id, file, data)
      .toPromise()
      .then((res) => res);
  }

  getInvoices(data?: any, filter?: any) {
    let headers = {
      params: data,
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "admin/invoice", filter, headers)
      .toPromise()
      .then((res) => res);
  }

  getPractices(data?: any, filter?: any) {
    let headers = {
      params: data,
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "admin/practice", filter, headers)
      .toPromise()
      .then((res) => res);
  }

  editStatus(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .put(this.baseUrl + "user/edit-status", data, headers)
      .toPromise()
      .then((res) => res);
  }

  sendMail(data) {
    let headers = {
      params: data,
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "user/send-email", data, headers)
      .toPromise()
      .then((res) => res);
  }

  editCaseAllowed(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .put(this.baseUrl + "user/edit-case-allowed", data, headers)
      .toPromise()
      .then((res) => res);
  }

  getPracticeDetail(data?: any) {
    let headers = {
      params: data,
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "user/doctors-list", headers)
      .toPromise()
      .then((res) => res);
  }

  editPractiseDetail(data: any, practise_id: any) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .put(
        this.baseUrl + "admin/edit-practice-info/" + practise_id,
        data,
        headers
      )
      .toPromise()
      .then((res) => res);
  }

  addDoctor(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "user/add-doctor", data, headers)
      .toPromise()
      .then((res) => res);
  }

  deleteDoctor(email) {
    let data = {};
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    data["email"] = email;
    return this.http
      .post(this.baseUrl + "user/remove-doctor", data, headers)
      .toPromise()
      .then((res) => res);
  }

  changePracticeAdmin(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "admin/change-practice-admin", data, headers)
      .toPromise()
      .then((res) => res);
  }

  changeBillingMethod(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .post(this.baseUrl + "admin/update-monthly-payment", data, headers)
      .toPromise()
      .then((res) => res);
  }

  dashboardCount() {
    let headers = {
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "admin/dashboard", headers)
      .toPromise()
      .then((res) => res);
  }

  monthlyPayment(data?: any, filter?: any) {
    let headers = {
      params: data,
      headers: { Authorization: localStorage.getItem("id_token") },
    };
    return this.http
      .get(this.baseUrl + "payment/monthlyPayment", headers)
      .toPromise()
      .then((res) => res);
  }

  storeUserData(token: string) {
    localStorage.setItem("id_token", token);
    this.authToken = token;
  }

  loadToken() {
    const token = localStorage.getItem("id_token");
    this.authToken = token;
    return localStorage.getItem("id_token");
  }

  loggedIn() {
    return this.loadToken() !== null;
  }

  logout() {
    this.authToken = null;
    localStorage.clear();
  }
}
