import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: 'login', loadChildren: './login/login.module#LoginModule'},
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule'},
  { path: 'forgotpassword', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule'},
  { path: 'superadmin', loadChildren: './super-admin/super-admin.module#SuperAdminModule', canActivate:[AuthGuard]},
  { path: 'admin', loadChildren: './admin/admin.module#AdminModule', canActivate:[AuthGuard]},
  { path: '', redirectTo: '/login', pathMatch: 'prefix' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
